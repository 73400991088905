import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
const ServiceVariants = ({ service, lang }) => {
    const [expandedVariants, setExpandedVariants] = useState({}); // Suivre l'état de chaque variante
    const [appear, setAppear] = useState([]); // Tableau pour gérer les délais de chaque élément
    const { t } = useTranslation();
    const toggleReadMore = (variantId) => {
        setExpandedVariants((prevState) => ({
            ...prevState,
            [variantId]: !prevState[variantId], // Basculer l'état pour ce variantId
        }));
    };

    const limit = 200; // Limite de caractères

    useEffect(() => {
        // Ajouter un délai progressif pour l'affichage des éléments
        const timeoutIds = service.variants_active.map((_, index) =>
            setTimeout(() => {
                setAppear((prev) => [...prev, index]); // Ajouter l'indice de l'élément à afficher
            }, index * 250) // Délai de 150ms entre chaque élément
        );

        // Nettoyer les timeouts lorsque le composant est démonté
        return () => timeoutIds.forEach((id) => clearTimeout(id));
    }, [service.variants_active]);

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-1 gap-0">
                {service.variants_active.map((variant, index) => {
                    const description = variant.description?.[lang] || "<p> </p>";
                    const truncatedDescription = description.slice(0, limit);
                    const isExpanded = expandedVariants[variant.id] || false; // Vérifier si ce variant est étendu

                    return (
                        <div
                            key={variant.id}
                            className={`relative cursor-pointer md:mx-6 opacity-0 transition-opacity duration-1000 ease-in-out ${
                                appear.includes(index) ? 'opacity-100' : ''
                            }`}
                        >
                            <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-[#C0B596] rounded-lg "></span>
                            <div className="relative px-4 hover:bg-[#f5f5f5] bg-white border border-[#C0B596] rounded-lg hover:scale-105 transition duration-500">
                                <div className="flex items-center">
                                    <h3 className="my-2 ml-3 text-lg font-bold text-gray-800">
                                        {variant.name[lang]}
                                    </h3>
                                </div>

                                <div
                                    className={`transition-all duration-1000 ease-in-out overflow-hidden ${
                                        isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-20'
                                    }`}
                                >
                                    <span
                                        className="text-gray-700 text-sm"
                                        dangerouslySetInnerHTML={{
                                            __html: isExpanded ? description : truncatedDescription,
                                        }}
                                    />
                                </div>

                                <div className="flex justify-between items-center">
                                    <span className="">
                                        {description.length > limit && (
                                            <button
                                                className="text-[#C0B596] hover:underline"
                                                onClick={() => toggleReadMore(variant.id)}
                                            >
                                                {isExpanded ?t("Less"):t("Read more") }
                                            </button>
                                        )}
                                    </span>
                                    <div
                                        key={variant.id}
                                        className="flex items-center pt-[15px] text-[15px] text-[#333] mb-2"
                                    >
                                        <span className="mr-5">{variant.duration} min</span>
                                        <span className="">
                                            {variant.price_formated} / {variant.measuring_unit.name[lang]}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default ServiceVariants;
