// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importez les fichiers de traduction pour chaque langue que vous souhaitez supporter
import translationEN from '../locales/en.json';
import translationFR from '../locales/fr.json';

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr', // Langue par défaut
    fallbackLng: 'fr', // Langue de secours si la traduction n'est pas trouvée
    interpolation: {
      escapeValue: false, // Ne pas échapper les valeurs HTML
    },
  });

export default i18n;
