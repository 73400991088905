import React  from 'react';
import appointment from '../../fonts/icons/time-management.svg';
import expertTeam from '../../fonts/icons/suggestion.svg';
import face from '../../fonts/icons/face.svg';
import { useTranslation } from 'react-i18next';

const Features = (props) => {
    const { t } = useTranslation();


    return (
        <div className="bg-[#FBF6EF] pt-[50px] pb-[30px] md:hidden">
            <div className="wraper antialiased">
                <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-4 md:col-span-6 sm:col-span-12 mb-5">
                        <div className="bg-[#FBF6EF] flex items-center sm:mx-[30px] col:mx-0">
                        <div className="bg-[#C0B596] h-[100px] col:h-[80px] col:w-[80px] w-[100px] text-center px-[18px] col:p-0 flex justify-center items-center">
                            <img
                                src={appointment}
                                className="fi text-[60px] col:text-[50px] col:leading-[55px] color-white"
                                alt="Time Management"
                            />
                    </div>
                            <div className="pl-[24px]">
                                <span className="text-[#C0B596] text-[16px] font-normal">{t('Book Your')}</span>
                                <h3 className="text-[#333] text-[27px] col:text-[22px] font-medium">{t('Appointment')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4 md:col-span-6 sm:col-span-12 mb-5">
                        <div className="bg-[#FBF6EF] flex items-center sm:mx-[30px] col:mx-0">
                            <div className="bg-[#C0B596] h-[100px] col:h-[80px] col:w-[80px] w-[100px] text-center px-[18px] col:p-0 flex justify-center items-center">
                            <img
                            src={expertTeam}
                            className="fi text-[60px] col:text-[50px] col:leading-[55px] text-white"
                            alt="Time Management"
                        />
                         </div>
                            <div className="pl-[24px]">
                                <span className="text-[#C0B596] text-[16px] font-normal">{t('Get Free')}</span>
                                <h3 className="text-[#333] text-[27px] col:text-[22px] font-medium">{t('Expert Advice')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4 md:col-span-6 sm:col-span-12 mb-5">
                        <div className="bg-[#FBF6EF] flex items-center sm:mx-[30px] col:mx-0">
                            <div className="bg-[#C0B596] h-[100px] col:h-[80px] col:w-[80px] w-[100px] text-center px-[18px] col:p-0 flex justify-center items-center">
                                <img
                                src={face}
                                className="fi text-[60px] col:text-[50px] col:leading-[55px] text-white"
                                alt="Time Management"
                            />
                            </div>
                            <div className="pl-[24px]">
                                <span className="text-[#C0B596] text-[16px] font-normal"></span>
                                <h3 className="text-[#333] text-[27px] col:text-[22px] font-medium">{t('Enjoy')}</h3>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Features;