import React from "react";
//import { Navigation, A11y } from 'swiper';
import { Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';
// import hero1 from '../../images/slider/slide-2.mov';
// import hero2 from '../../images/slider/slide1-done.webp';
// import hero3 from '../../images/slider/slide-4.jpg';

import { useTranslation } from 'react-i18next';

const Hero = (props) => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const slides = props.slides;

    const bookLink = process.env.REACT_APP_SQUARE_BOOK_LINK;
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    

    return (

        <section className="hero relative h-[790px] lg:h-[500px] col:h-[450px] overflow-hidden z-20">
            <div className="hero-slider">
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, A11y]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    speed={1800}
                    parallax={true}
                    navigation
                >

{slides.map((slide, index) => (
    <SwiperSlide key={index}>
        <div className="max-w-screen-xl mx-auto">
      
            {slide.media_type === 'video' ? (
                <div className="slide-inner slide-bg-image h-[890px] lg:h-[500px] bg-no-repeat col:h-[650px]">
                    <video autoPlay loop muted className="video-background absolute inset-0 w-full h-full object-cover">
                        <source src={slide.media_path} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="slide relative h-full bg-cover bg-no-repeat z-10 after:absolute after:content-[''] after:w-full after:h-full after:left-0 after:top-0 after:opacity-20 after:z-10">
                        <div className="wraper">
                            <div className="slide-caption w-[700px] md:w-[250px] mt-[245px] lg:mt-[110px] md:mt-[120px] col:mt-[100px] absolute top-0 z-20">
                                <h2 className="col:leading-[35px] font-normal leading-[90px] lg:leading-[56px] md:my-[20px] my-[33px] text-white text-[35px] text-gradient md:text-[20px] lg:text-[35px]">
                                    <span>{slide.text[lang]}</span>
                                </h2>
                                <div className="btns">
                                    <div className="btn-style">
                                    <Link onClick={ClickHandler}  to={bookLink} target="_blanck" className="bg-[#C0B596] relative cursor-pointer text-[24px] font-semibold text-white px-[38px] py-[12px] capitalize inline-block mt-[6px] transition ease-in-out duration-300 hover:bg-[#d4c291] col:mb-[5px] col:mt-[15px] col:text-[15px] col:px-[18px] col:py-[8px] before:absolute before:content-[''] before:w-[85px] before:h-[2px] before:left-[-60px] before:top-[50%] before:bg-white before:transform before:translate-y-[-50%] before:transition-all hover:before:left-[-95px] md:before:hidden">
                                            {t('Book now')}
                                     </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="slide-inner slide-bg-image h-[890px] lg:h-[500px] bg-no-repeat col:h-[650px]" 
                    style={{ backgroundImage: `url(${slide.media_path})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="slide relative h-full bg-cover bg-no-repeat z-10 after:absolute after:content-[''] after:w-full after:h-full after:left-0 after:top-0 after:opacity-20 after:z-10">
                        <div className="wraper">
                            <div className="slide-caption w-[700px] md:w-[250px] mt-[245px] lg:mt-[110px] md:mt-[120px] col:mt-[100px] absolute top-0 z-20">
                                <h2 className="col:leading-[35px] font-normal leading-[90px] lg:leading-[56px] md:my-[20px] my-[33px] text-white text-[35px] text-gradient md:text-[20px] lg:text-[35px]">
                                    <span>{slide.text[lang]}</span>
                                </h2>
                                <div className="btns">
                                    <div className="btn-style">
                                        <Link onClick={ClickHandler} to={bookLink} target="_blanck" className="bg-[#C0B596] relative cursor-pointer text-[16px] font-semibold text-white px-[38px] py-[12px] capitalize inline-block mt-[6px] transition ease-in-out duration-300 hover:bg-[#d4c291] col:mb-[5px] col:mt-[15px] col:text-[15px] col:px-[18px] col:py-[8px] before:absolute before:content-[''] before:w-[85px] before:h-[2px] before:left-[-60px] before:top-[50%] before:bg-white before:transform before:translate-y-[-50%] before:transition-all hover:before:left-[-95px] md:before:hidden">
                                            {t('Book now')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </SwiperSlide>
))}









{/*                     <SwiperSlide>
                        <div className="slide-inner slide-bg-image h-[790px] lg:h-[500px] col:h-[450px]">
                        <video autoPlay loop muted className="video-background absolute inset-0 w-full h-full object-cover">
                            <source src={hero1} type="video/mp4" />
                            Your browser does not support the video tag.
                         </video>
                            <div className="slide  relative h-full bg-cover bg-no-repeat z-10  after:absolute after:content-[''] 
            after:w-full after:h-full after:left-0 after:top-0 after:opacity-40 after:z-10  ">
                                <div className="wraper">
                                    <div className="slide-caption w-[700px] mt-[245px] lg:mt-[110px] md:mt-[120px] col:mt-[100px] absolute top-0 z-20">
                                        <p className="text-white text-[20px] font-light col:text-[18px]"></p>
                                        <h2 className="text-white text-[60px] leading-[90px] lg:text-[42px] lg:leading-[56px] 
                     col:text-[25px] col:leading-[35px] font-normal my-[33px] md:my-[20px]"><span> Votre satisfaction et le meilleur résultat possible  </span> </h2>
                                        <div className="btns">
                                            <div className="btn-style">
                                                <Link onClick={ClickHandler} to="/contact" className="bg-[#C0B596] relative cursor-pointer text-[16px]
                             font-semibold text-white px-[38px] py-[12px]  capitalize inline-block mt-[6px] 
                             transition ease-in-out duration-300 hover:bg-[#d4c291]
                            col:mb-[5px] col:mt-[15px] col:text-[15px] col:px-[18px] col:py-[8px] 
                            before:absolute before:content-[''] before:w-[85px] before:h-[2px] before:left-[-60px]
                             before:top-[50%]  before:bg-white before:transform before:translate-y-[-50%] 
                             before:transition-all hover:before:left-[-95px] md:before:hidden">Contact us now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide> */}

            

                
                </Swiper>
            </div>
        </section>
    )
}

export default Hero;