import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

const Newsletter = ({ withSize }) => {
    const { t} = useTranslation();


    // const SubmitHandler = (e) => {
    //     e.preventDefault()
    // }
    const [forms, setForms] = useState({
        email: '',
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        messages: {
            email: t('errors.invalid_email')
        },
    }));

    const changeHandler = (e) => {
        setForms({ ...forms, [e.target.name]: e.target.value })

        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            setForms({
                email: '',
            });
            try {
                const apiUrl = process.env.REACT_APP_API_URL + '/client-newsletter';
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(forms)
                });

                if (response.ok) {
                    document.getElementById('submit-message').classList.remove('hidden');
                    document.getElementById('submit-message').innerHTML = t('Thank you for subscribing to our newsletter');
                    console.log('Formulaire soumis avec succès !');
                } else {
                    document.getElementById('submit-message').classList.remove('hidden');
                    document.getElementById('submit-message').innerHTML = t('An error occurred while submitting the form');
                    console.error('Erreur lors de la soumission du formulaire !');
                }
            } catch (error) {
                document.getElementById('submit-message').classList.remove('hidden');
                document.getElementById('submit-message').innerHTML = t('An error occurred while submitting the form');
                console.error('Erreur lors de la soumission du formulaire :', error);
            }
        } else {
            validator.showMessages();
        }
    };

    return (
        <section className="bg-[#4B424D] pt-[30px] pb-[80px]">
              <div className={`wrapper ${withSize || ''}`}>
                <div className="flex justify-center antialiased">
                    {/* <div className="col-span-3 md:col-span-2 "></div> */}
                    <div className="">
                        <div className="text-center max-w-[443px] col:w-full mx-auto">
                            <h3 className="text-[24px] sm:text-[20px] font-medium mb-[40px] sm:mb-[30px] text-[#C0B596]">
                                {t('Subscribe  Newsletter')}
                            </h3>
                            <form className="relative" onSubmit={(e) => submitHandler(e)}>
                    
                               <input
                                    className='w-full bg-transparent h-[50px] text-white py-[2px] 
                                    pr-[10px] pl-[5px] col:pl-[10px] border border-[#ddd] focus:outline-0  rounded-[5px]'
                                    value={forms.email}
                                    type="email"
                                    name="email"
                                    onChange={(e) => changeHandler(e)}
                                    placeholder={t('Your Email')} />
                                {validator.message('email', forms.email, 'required|email')}

                                <button type="submit" className="bg-[#C0B596] text-white border-0 outline-0 absolute right-0 top-0 w-[125px] col:w-[90px] h-[50px]">
                                {t('subscribe')}
                                </button>
                            </form>
                            <div id="submit-message" className='hidden mt-3 text-[#CBB26A]' > </div>
                        </div>
                    </div>
                    {/* <div className="col-span-3 md:col-span-2"></div> */}
                </div>
            </div>
        </section>
    )
}

export default Newsletter;