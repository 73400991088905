import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import Sidebar from './Sidebar';
import CategoriesFooter from '../../components/CategoriesFooter';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';

import Navbar2 from '../../components/Navbar2/Navbar2';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';
import ImageGallery from '../../components/imageFilter/ImageGallery';
import ServiceVariants from './ServiceVariants';

const ServiceSinglePage = (props) => {
    const { slug, id } = useParams()
    const { apiSettings } = useAppContext();
    const { i18n } = useTranslation();
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    const info = apiSettings.settings;
    const lang = i18n.language;
    const catServices = info.catServices;
    const cateroryService = catServices.find(item => item.slug === slug);
    const servicesInCat = cateroryService.services_active;
    const service = servicesInCat.find(item => parseInt(item.id) == id);
    const service_image_path = service.image ? service.image_path : cateroryService.image_path;
    console.log('service_image', service.image);

    // console.log('has_images_variants', service.has_images_variants);

    return (
        <Fragment>
            <Navbar2 />

            <PageTitle pageTitle={service.name[lang]} pagesub={cateroryService.name[lang]} link={`/cat-service/${slug}`} image_path={service_image_path} />

            <div className="py-[30px] md:py-[2px]">
                <div className="wraper">
                    <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-9 md:col-span-12 order-1">
                            <div className="">
                                <h2 className="md:text-[18px] text-[36px] ">
                                    <span className="wrap text-[#333] ">
                                        <span className="">
                                            {service.name[lang]}
                                        </span>
                                    </span>
                                </h2>
                                <p className="text-[16px] leading-[30px] py-[20px] mb-[16px]">
                                    <span dangerouslySetInnerHTML={{ __html: service.description[lang] }}></span>
                                </p>
                            </div>

                        </div>
                        <div className="md:hidden col-span-3  col:col-span-12 order-2  mb-4 ">
                             <Sidebar lang={lang} slug={slug} id={id} catServices={catServices} />
                        </div>
                    </div>
                    <ServiceVariants service={service} lang={lang} />
                    {service.has_images_variants === true ? (
                            <ImageGallery service={service} lang={lang} catServices={catServices} />
                        ) : (
                    ''

                        )}

                    <div className="hidden md:flex md:flex-col md:order-3 m-4 ">
                         <Sidebar lang={lang} slug={slug} id={id} catServices={catServices} />
                   </div>

                </div>
            </div>
            <ConsultingArea />
            <div className="py-[100px] md:py-[80px]">
                    <div className="wraper">
                        <CategoriesFooter lang={lang} catServices={catServices} />
                    </div>
                </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default ServiceSinglePage;
