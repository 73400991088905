

import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Navbar2 from '../../components/Navbar2/Navbar2';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';


const PrivacyPolicies = () => {

    const { apiSettings } = useAppContext();
    const { t, i18n } = useTranslation();
    if (!apiSettings.settings || apiSettings.loading) {
        return <div> Loading...</div>;
    }
    const info = apiSettings.settings;
    const lang = i18n.language;
    const privacyPolicies = info.privancyPolicies[0];

    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={t('Privacy Policy')} pagesub={t('Privacy Policy')} />

            <div className="py-[100px] md:py-[80px]">
                <div className="wraper">
                    <div className="grid grid-cols-12 gap-3">

                        <div className="col-span-10 md:col-span-12 order-1">

                            <span dangerouslySetInnerHTML={{ __html: privacyPolicies.content[lang] }}></span>
                            <div className="text-center">
                               
                                <div className="text-[16px] ">
                                     {info.app_name[lang]} 
                                </div>
                                <div className="text-[16px] ">
                                    {t('Email')}: {info.email}
                                </div>
                                <div className="text-[16px]">
                                     {t('Phone')}: {info.phone_formated}
                                </div>
                                <div className="text-[16px]">
                                    {info.full_address}
                                </div>                         
                          

                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default PrivacyPolicies;
