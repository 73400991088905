import React from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../api/AppSettings';
import { useTranslation } from 'react-i18next';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';


const CatServices = (props) => {
 
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const {  apiSettings } = useAppContext();

    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    const categories = apiSettings.settings.catServices;
        return (
            <section className="bg-[#f5f5f5] pt-[95px] pb-[70px]">
                <div className="wraper antialiased">
                    <div className="col-span-12 text-center">
                        <div className="mb-[60px]">
                            <span className="text-[26px] text-[#C0B596]">{t('Area Of Practice')}</span>
                            <h2 className=" text-[36px] md:text-[26px] font-medium text-[#333] pb-[20px] relative before:absolute before:content-[''] before:left-[50%] before:bottom-0 before:transform before:-translate-x-1/2 before:w-[60px] before:h-[3px] before:bg-[#C0B596]">
                            {t('How Can We Help You')}  
                            </h2>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-3">
                    {categories.map((catService, Citem) => (
                            <div className="col-span-4 md:col-span-6 sm:col-span-12 mb-5" key={Citem}>
                                <div className="group flex sm:mx-[80px] col:mx-0">
                                    <div className="h-[80px] w-[80px] group-hover:text-[#999]">
                                        <div className="h-[80px] w-[80px] leading-[75px] border border-[#666] border-[rgba(192, 181, 150, .5)] rounded-[50%] text-center flex justify-center items-center">
                                        <Link onClick={ClickHandler} to={`/cat-service/${catService.slug}`} >
                                            <img
                                                src={catService.icon_path}
                                                className="w-[64%] fa col:text-[40px] col:leading-[45px] transition-all "
                                                alt="img"
                                             />
                                        </Link>
                                        </div>
                                    </div>
                                    <div className="pl-[24px]">
                                        <h3 className="text-[#373737] text-[24px] col:text-[20px] font-medium pb-[10px] relative mb-[10px]
                                        before:absolute before:content-[''] before:left-0 before:bottom-0 before:w-[30px] before:h-[2px] before:bg-[#C0B596]">
                                            <Link onClick={ClickHandler} to={`/cat-service/${catService.slug}`}>
                                                {catService.name[lang]}
                                            </Link>
                                        </h3>
                                        <p className="text-[#777] text-[16px] font-normal">
                                            {catService.description[lang].length > 150 ? (
                                                <>
                                                    <span dangerouslySetInnerHTML={{ __html: catService.description[lang].substring(0, 150) }}></span>
                                                    <Link className="text-[#C0B596]"   onClick={ClickHandler} to={`/cat-service/${catService.slug}`}>{t('Read More')}</Link>
                                                </>
                                            ) : (
                                                <span dangerouslySetInnerHTML={{ __html: catService.description[lang] }}></span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
           
            </section>
                   
        )
}

export default CatServices;
