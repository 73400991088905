import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [apiSettings, setApiSettings] = useState({
    loading: false,
    settings: null,
  });

  useEffect(() => {
    const fetchApiSettings = async () => {
      try {
        setApiSettings({ loading: true });
        const apiUrl = process.env.REACT_APP_API_URL + '/about';
        const response = await axios.get(apiUrl);
        // const allRepos = response.data;
        setApiSettings({ loading: false, settings: response.data });
      
      } catch (error) {
        setApiSettings({ loading: false, settings: null });
        console.error('Error fetching API settings:', error);
      }
    };
   
    fetchApiSettings();
  }, []); 

  return (
    <AppContext.Provider value={{ apiSettings  }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return React.useContext(AppContext);
};
