import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Navbar2 from '../../components/Navbar2/Navbar2';
import Contactpage from '../../components/Contactpage/Contactpage'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Newsletter from '../../components/Newsletter/Newsletter';
import { useTranslation } from 'react-i18next';
import contactUsImage from '../../images/contact/contact-us.jpeg';

const ContactPage =() => {
    const { t  } = useTranslation();
    return(
        <Fragment>
            <Navbar2/>
            <PageTitle pageTitle={t('Contact Us')} pagesub={t('Contact')}  image_path={contactUsImage} /> 
            <Contactpage/>
            <Newsletter />
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ContactPage;

