import React, { Fragment, useState, useEffect } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import CategoriesFooter from '../../components/CategoriesFooter';
import Navbar2 from '../../components/Navbar2/Navbar2';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';

const Prices = () => {
    const { apiSettings } = useAppContext();
    const { t, i18n } = useTranslation();
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };
    // État pour suivre le collapse des catégories et des services
    const [isCategoryOpen, setIsCategoryOpen] = useState({});
    const [isServiceOpen, setIsServiceOpen] = useState({});
    const [searchQuery, setSearchQuery] = useState(""); // Added state for search query

    useEffect(() => {
        if (apiSettings.settings) {
            const initialCategoryState = {};
            const initialServiceState = {};

            apiSettings.settings.catServices.forEach((catService, catIndex) => {
                initialCategoryState[catIndex] = true; // Toutes les catégories sont ouvertes par défaut

                catService.services_active.forEach((service) => {
                    initialServiceState[service.id] = true; // Tous les services sont ouverts par défaut
                });
            });

            setIsCategoryOpen(initialCategoryState);
            setIsServiceOpen(initialServiceState);
        }
    }, [apiSettings.settings]);
    // Fonction pour basculer le collapse d'une catégorie
    const toggleCategoryCollapse = (categoryId) => {
        setIsCategoryOpen((prevState) => ({
            ...prevState,
            [categoryId]: !prevState[categoryId],
        }));
    };

    // Fonction pour basculer le collapse d'un service
    const toggleServiceCollapse = (serviceId) => {
        setIsServiceOpen((prevState) => ({
            ...prevState,
            [serviceId]: !prevState[serviceId],
        }));
    };

    // Affichage du spinner si les données ne sont pas encore chargées
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }

    const info = apiSettings.settings;
    const lang = i18n.language;
    const catServices = info.catServices;
    const promotions = info.promotion;
    const itemsPromotions = promotions.flatMap((promo) => promo.items);
    // Filtering prices based on search query

    const filteredServices = catServices.map((catService) => {
        return {
            ...catService,
            services_active: catService.services_active.filter(service =>
                service.name[lang].toLowerCase().includes(searchQuery.toLowerCase()) ||
                service.variants_active.some(variant => variant.name[lang].toLowerCase().includes(searchQuery.toLowerCase()))
            )

        };

    });

    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={t('Our Prices')} pagesub={t('Our Prices')} />           

            <div className="py-[30px] md:py-[2px]">
                <div className="wraper ">
                    <div className="flex w-full">
                        <div className="w-[65%] mx-[3%] md:w-full md:mx-3">
                            {/* Search input */}

                            <div className="text-center relative">                        
                                <div className="w-full m-auto md:mt-3 ">
                                    <div className="relative  text-base text-[#333]  shadow-lg z-10">
                                        <input type="text"  value={searchQuery}
                                        placeholder={t('Search Service')}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className="rounded-lg shadow-sm focus:outline-none border-2 
                                        hover:scale-105 transition duration-500 
                                        py-3 px-3 block w-full"/>
                                    </div>
                                </div>
                            </div>
                     
                            {filteredServices.map((catService, Citem) => (
                                <div className="mt-[25px] w-full" key={Citem}>
                                    <div className="mb-8 organigation-text">
                                        {/* Titre de catégorie avec collapse */}
                                        <h2
                                            className="md:text-[18px] text-[20px] text-[#333] bg-[#c0b59659] mb-[8px] p-3
                                            transition ease-in-out duration-300 hover:bg-[#d4c2916a] block cursor-pointer"
                                            onClick={() => toggleCategoryCollapse(Citem)}  >
                                            <i
                                                className={` fa ${isCategoryOpen[Citem] ? 'fa-chevron-down' : 'fa-chevron-right'}  pr-2`}
                                            ></i>               

                                            {catService.name[lang]}


                                        </h2>

                                        {/* Liste des services si la catégorie est ouverte */}
                                        {isCategoryOpen[Citem] && catService.services_active.map((service, item) => {

                                            // Affichage direct si un seul variant
                                            if (service.variants_active.length === 1) {
                                                const variant = service.variants_active[0];
                                                const promotionItem = itemsPromotions.find(
                                                    (promo) => promo.service_variant_id === variant.id
                                                );
                                                return (
                                                    <div
                                                        key={item}
                                                        className="flex md:flex-col flex-row justify-between  px-4 py-2 mb-3 hover:bg-[#f5f5f5] bg-white border-b-2 border-gray-200 
                                                        border-opacity-60 rounded-lg hover:scale-105 transition duration-500">
                                                        <div className="flex items-start" >    
                                                            <span className="">
                                                                <Link
                                                                    onClick={ClickHandler}
                                                                    to={`/service/${catService.slug}/${service.id}`}
                                                                    className="transition ease-in-out duration-300 hover:text-[#C0B596]"
                                                                >
                                                                    {variant.name[lang]}
                                                                </Link>
                                                                {promotionItem ? (
                                                                            <Link onClick={ClickHandler}
                                                                                    to={`/promotions`}
                                                                                    className="text-[12px] md:text-[8px] animate-pulse  focus:animate-none hover:animate-none " title="PROMOTION">
                                                                                    <span className="rotate-[-6deg] ml-[8px] text-green-600 font-extrabold relative inline-block stroke-current">
                                                                                        PROMO
                                                                                        <svg className="absolute -bottom-0.5 w-full max-h-1.5" viewBox="0 0 55 5" xmlns="http://www.w3.org/2000/svg"
                                                                                            preserveAspectRatio="none">
                                                                                            <path d="M0.652466 4.00002C15.8925 2.66668 48.0351 0.400018 54.6853 2.00002" strokeWidth="2"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                            </Link>           
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                            </span>
                                                        </div>
                                                        <div className="flex md:justify-end text-[15px] text-[#333]" >
                                                            <span className="">                                                                                                                                
                                                             ${variant.price_formated}/{variant.unit.name[lang]}                                                                        
                                 
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            // Affichage des services avec plusieurs variantes et collapse
                                            return (
                                                <div key={item} className="ml-2">
                                                    {/* Titre du service avec collapse */}
                                                    <div
                                                        className=" md:text-[16px] text-[18px] m-2 p-2 items-center bg-[#c0b59629] text-[#333] pb-3 
                                                        transition ease-in-out duration-300 hover:bg-[#d4c29145] lg:border-0 md:border-b cursor-pointer"
                                                        onClick={() => toggleServiceCollapse(service.id)} >
                                                        <i
                                                            className={`fa ${isServiceOpen[service.id] ? 'fa-chevron-down' : 'fa-chevron-right'} text-[#333] pr-[15px]`}
                                                            aria-hidden="true"
                                                        ></i>
                                                        <span className=" ">
                                                            {service.name[lang]}
                                                        </span>
                                                    </div>

                                                    {/* Variantes de service affichées si le collapse est ouvert */}
                                                    {isServiceOpen[service.id] && service.variants_active.map((variant, variantIndex) => {

                                                        const promotionItem = itemsPromotions.find(
                                                            (promo) => promo.service_variant_id === variant.id
                                                        );
                                                        return (
                                                            <div
                                                                key={variantIndex}
                                                                className="ml-[4%] flex md:flex-col flex-row justify-between  px-4 py-2 mb-3 hover:bg-[#f5f5f5] bg-white border-b-2 border-gray-200 
                                                        border-opacity-60 rounded-lg hover:scale-105 transition duration-500">
                                                                <div className="flex items-start" >
                                                                    <span className="">
                                                                
                                                                        <Link
                                                                            onClick={ClickHandler}
                                                                            to={`/service/${catService.slug}/${service.id}`}
                                                                            className="transition ease-in-out duration-300 hover:text-[#C0B596]"
                                                                        >
                                                                            {variant.name[lang]}
                                                                        </Link>
                                                                        {promotionItem ? (
                                                                            <Link onClick={ClickHandler}
                                                                                    to={`/promotions`}
                                                                                    className="text-[12px] md:text-[8px] animate-pulse  focus:animate-none hover:animate-none " title="PROMOTION">
                                                                                    <span className="rotate-[-6deg] ml-[8px] text-green-600 font-extrabold relative inline-block stroke-current">
                                                                                        PROMO
                                                                                        <svg className="absolute -bottom-0.5 w-full max-h-1.5" viewBox="0 0 55 5" xmlns="http://www.w3.org/2000/svg"
                                                                                            preserveAspectRatio="none">
                                                                                            <path d="M0.652466 4.00002C15.8925 2.66668 48.0351 0.400018 54.6853 2.00002" strokeWidth="2"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                            </Link>           
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div className="flex md:justify-end text-[15px]" >
                                                                <span className="">                                                                                                                                
                                                                    ${variant.price_formated}/{variant.unit.name[lang]}                                                                        
                                                                      
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    )}

                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <Sidebar catServices={catServices} lang={lang} />

                    </div>

                </div>
            </div>
            <ConsultingArea />
            <div className="py-[100px] md:py-[80px]">
                <div className="wraper">
                    <CategoriesFooter lang={lang} catServices={catServices} />
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default Prices;
