import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const Search = ({ currentLanguage }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [dynamicRoutes, setDynamicRoutes] = useState([]);
  const searchRef = useRef(null); // Référence pour détecter les clics en dehors
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const fetchCategoriesAndServices = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL + '/about';
        const response = await axios.get(apiUrl);
        const data = response.data.catServices;

        const routes = data.flatMap((category) => {
          const categoryRoute = {
            path: `/cat-service/${category.slug}`,
            title: category.name,
          };

          const serviceRoutes = category.services_active.map((service) => ({
            path: `/service/${category.slug}/${service.id}`,
            title: service.name,
          }));

          return [categoryRoute, ...serviceRoutes];
        });

        setDynamicRoutes(routes);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchCategoriesAndServices();

    // Gestion des clics en dehors
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setResults([]); // Cacher les résultats
        setQuery("");   // Réinitialiser la recherche
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setQuery(searchQuery);

    const allRoutes = [...dynamicRoutes];
    if (searchQuery.length > 0) {
      const filteredResults = allRoutes.filter((route) =>
        route.title[currentLanguage].toLowerCase().includes(searchQuery)
      );
      setResults(filteredResults);
    } else {
      setResults([]);
    }
  };

  return (
    <div ref={searchRef} className="w-full flex justify-center md:justify-start lg:pr-5 pr-0">
      <div className="relative w-full max-w-[90%] md:max-w-none md:pl-[30px]">
        <input
          className="bg-white w-full h-[40px] md:h-[35px] pl-[10px] pr-[40px] md:pr-[0px]
          focus-visible:outline-0 border ml-auto rounded-[5px] border-[rgba(64,59,59,0.07)]" 
          placeholder={t('Search here')} 
          type="text"
          value={query}
          onChange={handleSearch}
        />
        <i className="fa fa-search absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"></i>
      </div>
      
      {results.length > 0 && (
        <ul className="absolute mt-14 lg:w-[200%] md:w-[100%] lg:left-[-80%] md:left-[1%] z-[111] md:bg-[#4b424de4]  bg-[#4b424da8] shadow-[#3e419f17] transition-all  group-hover:top-full">
          {results.map((result, index) => (
            <li key={index} className="mt-[10px] pl-4 pr-4">
              <a
                className="text-[15px] lg:text-[15px] uppercase inline-block py-[2px] font-bold transition-all text-white hover:text-[#C0B596]"
                href={result.path}
              >
                {result.title[currentLanguage]}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Search;
