import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

const ImageGallery = (props) => {
  const { service, lang } = props;
  const urlImg = process.env.REACT_APP_API_STORAGE;
  //const urlImg = 'https://admin.yalisse.ca/storage/';
  console.log('urlImg', urlImg);
  const { t } = useTranslation();

  const [filteredImages, setFilteredImages] = useState([]);
  const [currentVariant, setCurrentVariant] = useState("all");
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const galleryRef = useRef(null);

  const images = useMemo(() => {
    const result = {};
    if (service) {
      const variants = service.variants_active || [];
      variants.forEach((variant) => {
        const variantId = variant.id;
        result[variantId] = (variant.images || []).map((image) => urlImg + image);
      });
    }
    return result;
  }, [service]);
  const allImages = useMemo(() => Object.values(images).flat(), [images]);

  // const allImages = Object.values(images).flat();
  // const variants = service.variants_active;

  useEffect(() => {
    setFilteredImages(allImages);
  }, [allImages]);

  const handleFilterChange = (variantId) => {
    setCurrentVariant(variantId);
    setFilteredImages(variantId === "all" ? allImages : images[variantId] || []);
  };

  const handleImageClick = (src, index) => {
    setSelectedImage(src);
    setCurrentImageIndex(index);
  };

  const handleNextImage = () => {
    const nextIndex = (currentImageIndex + 1) % filteredImages.length;
    setCurrentImageIndex(nextIndex);
    setSelectedImage(filteredImages[nextIndex]);
  };

  const handlePrevImage = () => {
    const prevIndex = (currentImageIndex - 1 + filteredImages.length) % filteredImages.length;
    setCurrentImageIndex(prevIndex);
    setSelectedImage(filteredImages[prevIndex]);
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  const handleOverlayClick = (e) => {
    // Fermer l'image si on clique en dehors de l'image
    if (e.target === e.currentTarget) {
      handleCloseImage();
    }
  };

  const renderVariantButtons = () => {
    if (!service?.variants_active) return null;

    const variants = service.variants_active;

    return (
      <>
        {variants.length > 1 && (
          <button
            onClick={() => handleFilterChange("all")}
            className={`group px-4 py-2 text-md font-medium rounded ${currentVariant === "all"
              ? "bg-[#cbbc99] text-white font-bold"
              : "bg-white text-[#C0B596] font-bold"
              }`}
          >
            {t("All")}
            <div className="bg-[#C0B596] h-[3px] w-0 group-hover:w-full transition-all duration-500"></div>
          </button>
        )}

        {variants.map((variant) => {
          // Récupérer le nom du service
          const fullName = variant.name?.[lang] || variant.name?.lang || t("Unnamed Service");

          // Limiter le texte à 15 caractères
          const truncatedName = fullName.length > 15 ? fullName.slice(0, 15) + "..." : fullName;

          return (
            <button
              key={variant.id}
              onClick={() => handleFilterChange(variant.id)}
              title={fullName} // Ajout du titre complet
              className={`group px-4 py-2 text-md font-medium rounded ${currentVariant === variant.id
                ? "bg-[#cbbc99] text-white font-bold"
                : "bg-white text-[#C0B596] font-bold"
                }`}
            >
              {truncatedName} {/* Afficher le texte tronqué */}
              <div className="bg-[#C0B596] h-[3px] w-0 group-hover:w-full transition-all duration-500"></div>
            </button>
          );
        })}

      </>
    );
  };

  return (
    <div className="wraper w-full bg-white">
      <section className="px-4 py-4 mx-auto">
        <div className="w-full mx-auto text-left md:w-11/12 xl:w-9/12 md:text-center">
          <div className="flex items-center justify-center py-4 md:py-8 flex-wrap gap-2">
            {renderVariantButtons()}
          </div>
        </div>
      </section>

      <section className="w-full h-full select-none">
        <div className="max-w-8xl md:w-full px-20 md:px-1">
          <ul
            ref={galleryRef}
            id="gallery"
            className="grid grid-cols-5 md:grid-cols-2 gap-2"
          >
            {filteredImages.map((src, idx) => (
              <li key={idx}>
                <a
                  href="#!"
                  onClick={() => handleImageClick(src, idx)}
                  className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40"
                >
                  <img
                    src={src}
                    alt={src}
                    className="absolute inset-0 h-full w-full object-cover group-hover:scale-150 transition-transform duration-500 ease-in-out"
                  />
                  {/* <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div> */}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {selectedImage && (
         
          <div
            onClick={handleOverlayClick}
            className="z-[999] fixed inset-0 flex justify-center items-center bg-black backdrop-blur bg-opacity-35 py-5 "
          >

            <div className="flex justify-center items-center w-full" onClick={handleOverlayClick} >
              <div
                onClick={handlePrevImage}
                className="absolute md:ml-[2%] ml-[15%] border border-white z-750 top-1/2 left-0 flex items-center justify-center text-white 
               rounded-full cursor-pointer bg-[#4b424da8] w-14 h-14 hover:bg-[#C0B596]" >
                <svg className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none" viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor" >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </div> 

                  <img
                    src={selectedImage}
                    alt={selectedImage}
                    className="object-fill max-h-[95%] max-w-[50%] md:max-w-[60%] sm:max-w-[70%] object-center rounded-lg "
                  />

              <div
                onClick={handleNextImage}
                className="absolute md:mr-[2%] mr-[15%] border border-white z-750  top-1/2 right-0 flex items-center justify-center text-white 
               rounded-full cursor-pointer bg-[#4b424da8] w-14 h-14 hover:bg-[#C0B596]"
              >
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>
          </div>

        )}
      </section>
                   {/* <div
                className="relative  ml-[75%] border border-white z-[999] top-[10%] left-0 flex items-center justify-center text-white 
                  rounded-full cursor-pointer bg-[#4b424da8] w-10 h-10 hover:bg-[#C0B596]" >
                <svg
                  className="w-6 h-6"
                  onClick={handleOverlayClick}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 6l12 12M18 6L6 18"
                  />
                </svg>
              </div> */}
    </div>
  );
};

export default ImageGallery;
