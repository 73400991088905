import React from 'react';

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Newsletter from '../../components/Newsletter/Newsletter';
import SideBarContactUs from '../../components/SideBarContactUs/SideBarContactUs';
const Sidebar = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const catServices = props.catServices;
    const lang = props.lang;
    //   const slug = props.slug;
    const { t } = useTranslation();
    return (
        // before:bg-[rgba(0,0,0,.65)]
        <div className="w-[25%] ml-[2%] md:hidden">
            <div className="lg:pr-0 md:mt-[40px]">              
                <SideBarContactUs />
                <div className="bg-[#f5f5f5] mt-[60px]">
                    <div className="bg-[#ddd] py-[10px] px-[20px] relative  
                  before:z-10 before:absolute before:contnent-[''] before:border-[15px] before:border-transparent 
                  before:border-t-[13px] before:border-t-[rgba(0,0,0,.1)]
                   before:top-full before:left-[30px] ">
                        <h3 className="text-[24px] text-[#282e3f]">{t('Categories')}</h3>
                    </div>
                    <div className="pt-[15px] px-[30px] pb-[20px]">
                        <ul>
                            {catServices.map((cat, item) => (
                                <li className="border-b border-[#ddd]" key={item}>
                                    <Link onClick={ClickHandler} to={`/cat-service/${cat.slug}`}
                                        className="text-[15px] pt-[15px] pb-[10px] text-[#333] block transition-all ease-in-out hover:text-[#C0B596]">
                                        {cat.name[lang]}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mt-[40px] ">
                 <Newsletter withSize='mx-10' />
            </div>
        </div>
         
    )
};
export default Sidebar;

