import React, { Fragment, useState } from 'react';
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/List";
import List from "@material-ui/core/List";
import { Link, useLocation } from "react-router-dom";
import './style.css';
import { useTranslation } from 'react-i18next';

const MobileMenu = (props) => {
    const { t, i18n } = useTranslation();
    const location = useLocation(); // Récupérer la route actuelle
    const catServices = props.catServices;
    const lang = props.lang;

    const menus = [
        {
            id: 1,
            title: t('Home'),
            link: '/home',
        },
        {
            id: 2,
            title: t('About'),
            link: '/about',
        },
        {
            id: 3,
            title: t('Promotions'),
            link: '/promotions',
        },
        {
            id: 4,
            title: t('Our Prices'),
            link: '/prices',
        },
        {
            id: 10,
            title: t('Categories'),
            submenu: catServices.map((catService, index) => ({
                id: 1000 + index,
                title: catService.name[lang],
                link: `/cat-service/${catService.slug}`,
            })),
        },
        {
            id: 88,
            title: t('Contact'),
            link: '/contact',
        }
    ];

    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const handleSubMenuClick = () => {
        setMenuState(false); // Ferme le menu mobile lorsqu'un sous-menu est cliqué
    };

    return (
        <div className='mobail_menu antialiased'>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close rounded-full ">
                    <div className="clox " onClick={() => setMenuState(!menuActive)}><i className="ti-close "></i></div>
                </div>

                <ul className="responsivemenu">
                    {menus.map((item, mn) => {
                        const isActive = location.pathname === item.link; // Vérifie si la route actuelle correspond à l'élément du menu
                        return (
                            <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                                {item.submenu ?
                                    <Fragment>
                                        <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                            <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                        </p>
                                        <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                            <List className="subMenu">
                                                <Fragment>
                                                    {item.submenu.map((submenu, i) => {
                                                        const isSubmenuActive = location.pathname === submenu.link;
                                                        return (
                                                            <ListItem key={i}>
                                                                <Link onClick={() => { ClickHandler(); handleSubMenuClick(); }}
                                                                    className={isSubmenuActive ? "active" : ""} 
                                                                    to={submenu.link}>{submenu.title}</Link>
                                                            </ListItem>
                                                        )
                                                    })}
                                                </Fragment>
                                            </List>
                                        </Collapse>
                                    </Fragment>
                                    : <Link 
                                        className={isActive ? "active" : ""} 
                                        to={item.link}>{item.title}</Link>
                                }
                            </ListItem>
                        )
                    })}

                    {/* Ajout des boutons de changement de langue */}
                    <li className="flex items-start justify-start mt-4 ml-10">
                        <button 
                            onClick={() => changeLanguage('en')} 
                            className={`relative group text-[15px] lg:text-[14px] block uppercase font-base-font font-normal hover:text-white
                            transition-all ${lang === 'en' ? 'text-[#C0B596]' : 'text-white'}`}>
                            En
                        </button>
                        <span className="mx-2 text-white">/</span>
                        <button 
                            onClick={() => changeLanguage('fr')} 
                            className={`relative group text-[15px] lg:text-[14px] block uppercase font-base-font font-normal hover:text-white
                            transition-all ${lang === 'fr' ? 'text-[#C0B596]' : 'text-white'}`}>
                            Fr
                        </button>
                    </li>

                </ul>

            </div>

            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    )
}

export default MobileMenu;
