import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import CategoriesFooter from '../../components/CategoriesFooter';

import Navbar2 from '../../components/Navbar2/Navbar2';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
const Promotions = () => {
    const { apiSettings } = useAppContext();
    const { t, i18n } = useTranslation();

    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }

    const info = apiSettings.settings;
    const lang = i18n.language;
    const catServices = info.catServices;
    const promotions = info.promotion;

    const promotionWithCategorie = promotions.filter(
        (promotion) => promotion.item_category_id !== null
    );
    console.log('promotionWithCategorie', promotionWithCategorie);

    const promotionsSpecial = promotions.filter(
        (promotion) => promotion.type_promo === 'special'
    );

    const promotionsByService = promotions.filter(
        (promotion) => promotion.type_promo === 'by_service'
    );

    console.log('promotionsByService', promotionsByService);
    const promotionsCombo = promotions.filter(
        (promotion) => promotion.type_promo === 'combo'
    );

    // console.log('promotionsWithoutCategory', promotionsWithoutCategory);
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={t('Promotions')} pagesub={t('Promotions')} />

            <div className="py-[30px] md:py-[2px]">
                <div className="wraper">
                    <div className="flex w-full">
                        <div className="w-[65%] mx-[3%] md:w-full md:mx-3">
                            <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" rel="stylesheet" />

  



                            {catServices.map((category) => {
                                // Filtrer les promotions par ID de catégorie
                                const promotionInCategories = promotionWithCategorie.filter(
                                    (promotion) => promotion.item_category_id === category.id
                                );
                                // console.log('promotionInCategories', promotionInCategories);

                                // Si des promotions existent pour cette catégorie, les rendre
                                return promotionInCategories.length > 0 ? (
                                    <div className="mt-[25px] w-full" key={category.id}>
                                        <div className="mb-8 organigation-text">
                                            <h2 className="text-[24px] text-[#282e3f] mb-[8px] block">
                                                <Link onClick={ClickHandler} to={`/cat-service/${category.slug}`}>
                                                    {category.name[lang]}
                                                </Link>
                                            </h2>

                                            {promotionInCategories.map((promotion) => {
                                                const service = category.services_active.find(
                                                    (service) => service.id === promotion.item_service_id
                                                );

                                                return service ? (
                                                    <div key={promotion.id} className="flex justify-between items-center px-4 py-2 mb-3 hover:bg-[#f5f5f5] bg-white border-b-2 border-gray-200 
                                                        border-opacity-60 rounded-lg hover:scale-105 transition duration-500">
                                                        <div className="flex flex-col w-full">
                                                            <div className="flex flex-row w-full">
                                                                {/* <i className="fa fa-star text-[#C0B596] pr-[15px]" aria-hidden="true"></i> */}
                                                                <span className="w-full">
                                                                    <Link
                                                                        onClick={ClickHandler}
                                                                        to={`/service/${category.slug}/${service.id}`}
                                                                        className="transition ease-in-out duration-300 text-[#333] hover:text-[#C0B596]"
                                                                    >
                                                                        {promotion.name[lang]}
                                                                    </Link>
                                                                </span>


                                                                {promotion.description[lang] && (
                                                                    <div className="flex items-center ml-2 md:ml-0 w-full">
                                                                        <span className="text-[14px]" dangerouslySetInnerHTML={{ __html: promotion.description[lang] }} /> {/* Render HTML safely */}

                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="flex md:flex-col flex-row justify-between w-full mt-4">
                                                                <span className="flex md:justify-end md:text-[8px] text-[12px] text-[#282e3f" >
                                                                    {promotion.start_date_formated} - {promotion.end_date_formated}
                                                                </span>
                                                                <span className="md:justify-end flex items-center">
                                                                    <s className="md:text-[12px] text-[15px] line-through text-black-500 mr-2">{promotion.price_before} $</s>
                                                                    <span className="text-[#282e3f]">{promotion.price_after} $</span>
                                                                    <span className="text-[#282e3f]"> / {promotion.unit.name[lang]} </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null;
                                            })}
                                        </div>
                                    </div>
                                ) : null;
                            })}
                            {/* Section pour les promotions combo */}
                            {promotionsCombo.length > 0 && (
                                <div className="mt-[25px] w-full">
                                    <h2 className="text-[24px] text-[#282e3f] mb-[8px] block">
                                        {t('Combo')}
                                    </h2>
                                    {promotionsCombo.map((promotion) => (
                                        <div key={promotion.id} className="flex justify-between items-center px-4 py-2 mb-3 hover:bg-[#f5f5f5] bg-white border-b-2 border-gray-200 
                                                        border-opacity-60 rounded-lg hover:scale-105 transition duration-500">
                                            <div className="flex flex-col w-full">
                                                <div className="flex flex-row w-full">
                                                    {/* <i className="fa fa-star text-[#C0B596] pr-[15px]" aria-hidden="true"></i> */}
                                                    <span className="w-full">
                                                        {promotion.name[lang]}
                                                    </span>
                                                </div>
                                                <div className="flex flex-row w-full">
                                                    {promotion.description[lang] && (
                                                         <div className="flex items-center ml-2 md:ml-0 w-full">
                                                            <span className="text-[16px]" dangerouslySetInnerHTML={{ __html: promotion.description[lang] }} /> {/* Render HTML safely */}
                                                        </div>
                                                    )}
                                                </div>
                                                {promotion.items.map((item) => (
                                                    <div className="flex md:flex-col flex-row justify-between w-full pl-2">
                                                        <div className="flex md:flex-col flex-row w-full ">
                                                            <Link onClick={ClickHandler}
                                                                to={`/service/${item.category.slug}/${item.serviceV.id}`}
                                                                className="transition ease-in-out duration-300 hover:text-[#C0B596]"
                                                            >
                                                                {item.serviceV.name[lang]} -  {item.name[lang]}
                                                            </Link>
                                                        </div>
                                                        <div className="flex flex-row w-full justify-end ">
                                                            <span className="  ">
                                                                {item.price_before} $ / {item.unit.name[lang]}
                                                            </span>
                                                        </div>
                                                    </div>                                                    
                                                ))}
                                                <div className="flex md:flex-col flex-row justify-between w-full mt-4">
                                                    <span className="flex md:justify-end md:text-[8px] text-[12px] text-[#282e3f" >
                                                        {promotion.start_date_formated} - {promotion.end_date_formated}
                                                    </span>
                                                    <span className="md:justify-end flex items-center">
                                                        <s className="md:text-[12px] text-[15px] line-through text-black-500 mr-2">{promotion.price_before} $</s>
                                                        <span className="text-[#282e3f]">{promotion.price_after} $</span>
                                                        <span className="text-[#282e3f]"> / {promotion.unit.name[lang]} </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    ))}

                                </div>
                            )}

                            {/* Section pour les promotions sans catégorie */}
                            {promotionsSpecial.length > 0 && (
                                <div className="mt-[25px] w-full">
                                    <h2 className="text-[24px] text-[#282e3f] mb-[8px] block">
                                        {t('Specials')}
                                    </h2>
                                    {promotionsSpecial.map((promotion) => (
                                        <div key={promotion.id} className="flex justify-between items-center px-4 py-2 mb-3 hover:bg-[#f5f5f5] bg-white border-b-2 border-gray-200 
                                                        border-opacity-60 rounded-lg hover:scale-105 transition duration-500">
                                            <div className="flex flex-col w-full">
                                                <div className="flex flex-row w-full">
                                                    <span className="w-full">
                                                        {promotion.name[lang]}
                                                    </span>
                                                </div>
                                                <div className="flex flex-row w-full">
                                                    {promotion.description[lang] && (
                                                        <div className="flex items-center ml-2 md:ml-0 w-full">
                                                            <span className="text-[16px]" dangerouslySetInnerHTML={{ __html: promotion.description[lang] }} /> {/* Render HTML safely */}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="flex md:flex-col flex-row justify-between w-full mt-4">
                                                    <span className="flex md:justify-end md:text-[8px] text-[12px] text-[#282e3f" >
                                                        {promotion.start_date_formated} - {promotion.end_date_formated}
                                                    </span>
                                                    <span className="md:justify-end flex items-center">
                                                        <s className="md:text-[12px] text-[15px] line-through text-black-500 mr-2">{promotion.price_before} $</s>
                                                        <span className="text-[#282e3f]">{promotion.price_after} $</span>
                                                        <span className="text-[#282e3f]"> / {promotion.unit.name[lang]} </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <Sidebar catServices={catServices} lang={lang} />
                    </div>
                </div>
            </div>
            <ConsultingArea />
            <div className="py-[100px] md:py-[80px]">                                                    {/* <i className="fa fa-star text-[#C0B596] pr-[15px]" aria-hidden="true"></i> */}

                <div className="wraper">
                    <CategoriesFooter lang={lang} catServices={catServices} />
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default Promotions;
