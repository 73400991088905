import React from 'react';
import freeConsultingImg from '../../images/contact/freeCosulting.jpeg';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


const SideBarContactUs = () => {
    const { t } = useTranslation();
    return (


        <div className="relative z-10 before:z-10 before:absolute before:contnent-['']  before:top-0 before:left-0 before:w-full before:h-full ">
            <img className="w-full bg-[#1d3a47] " src={freeConsultingImg} alt="" />
            <div className="absolute top-0 left-0 w-full h-full flex justify-center flex-col text-center px-[40px] pb-[20px] z-20">
                <div className="btn-style mt-[120px] ">
                    <Link className="w-full rounded-lg bg-[#4B424D] cursor-pointer  font-bold text-white px-[38px] py-[12px]  
                      capitalize inline-block mt-[6px] transition ease-in-out duration-300 hover:bg-[#4b424da8]
                      col:mb-[5px] col:mt-[15px] col:text-[18px] col:px-[18px] col:py-[2px] " to="/contact">{t('Contact us now')}</Link>
                </div>
            </div>
        </div>
    )
};

export default SideBarContactUs;